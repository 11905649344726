<!-- 
 * @description: 落地页
 * @author: 贺佳 
 * @date: 2023-06-20 10:57:00
!-->
<template>
  <div class="content">
    <div class="weixin" v-if="isWeiXin">
      <div class="img-box">
        <img :src="imgUrl" alt="" />
      </div>
      <div class="title">{{ game_name }}</div>
      <div class="btn-box">请点击右上角下载应用</div>
      <div class="back">
        <div class="tips">点击右上角，在浏览器打开</div>
      </div>
    </div>
    <div class="browser" v-else>
      <div class="img-box">
        <img :src="imgUrl" alt="" />
      </div>
      <div class="title">{{ game_name }}</div>
      <div class="descript">新人注册最高领取200元红包</div>
      <div class="midden-box">
        <div class="img-box">
          <img src="@/assets/common/red_envelope.png" alt="" />
        </div>
      </div>
      <a v-if="android_url" :href="android_url" class="download-android">
        <img src="@/assets/common/changyonglogo.png" alt="" /><span
          >Android下载</span
        >
      </a>
      <a v-if="ios_url" :href="ios_url" class="download-iphone">
        <img src="@/assets/common/ios.png" alt="" /><span>iPhone下载</span>
      </a>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      isWeiXin: true,
      imgUrl: "",
      game_name: "",
      android_url: "",
      ios_url: "",
    };
  },
  created() {
    if (this.$common.isWeiXin()) {
      this.isWeiXin = true;
    } else {
      this.isWeiXin = false;
    }
    this.getGameInfo();
  },
  methods: {
    //获取游戏信息
    getGameInfo() {
      let requestData = {
        // tenant_id: this.$route.query.tenant_id,
        // game_id: this.$route.query.game_id,
        // channel_id: this.$route.query.channel_id,
        // invite_code: this.$route.query.invite_code,
        id:this.$route.query.id
      };
      this.$api.redPacket.get_game_info(requestData).then((res) => {
        if (res.data.code === 200) {
          let responseData = res.data.data;
          this.imgUrl = responseData.icon;
          this.game_name = responseData.name;
          this.android_url = responseData.android_url;
          this.ios_url = responseData.ios_url;
        }
      });
    },
  },
};
</script>
<style scoped>
.weixin {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
  position: relative;
  /* overflow-y: scroll; */
}
.content .back {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  overflow-y: scroll;
}

.content .back .tips {
  position: absolute;
  padding: 26px 16px 16px 16px;
  top: 0;
  right: 16px;
  width: 116px;
  height: 81px;
  font-size: 14px;
  color: #333333;
  background: url("../../assets/common/tips.png") no-repeat center/cover;
}
.img-box {
  width: 98px;
  height: 98px;
  border-radius: 20px;
  margin: 30px auto 0;
}
.img-box img {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.title {
  font-size: 16px;
  color: #000000;
  text-align: center;
  margin-top: 20px;
  font-weight: 600;
}
.btn-box {
  width: 250px;
  height: 48px;
  line-height: 48px;
  text-align: center;
  color: #fff;
  background: #13c6ce;
  border-radius: 31px;
  margin: 248px auto;
  font-size: 14px;
  font-weight: 600;
}
.browser {
  width: 100vw;
  height: 100vh;
  background: url("../../assets/common/background.png") no-repeat center/cover;
  overflow: hidden;
  overflow-y: scroll;
}
.descript {
  font-size: 20px;
  color: #bf2424;
  text-align: center;
  margin-top: 36px;
}
.midden-box {
  width: 100%;
  height: 155px;
  background: #ffffff;
  margin-top: 18px;
  overflow: hidden;
}
.midden-box .img-box {
  width: 117px;
  height: 117px;
  margin: 35px auto 3px;
}
.midden-box .img-box img {
  width: 100%;
  height: 100%;
}
.download-android {
  width: 250px;
  height: 48px;
  border-radius: 31px;
  background: #13c6ce;
  margin: 35px auto;
  padding-left: 54px;
  display: flex;
  align-items: center;
}
.download-android span {
  font-size: 20px;
  line-height: 48px;
  color: #ffffff;
  /* font-weight: 600; */
}
.download-android img {
  display: inline-block;
  width: 20px;
  height: 22px;
  margin-right: 8px;
}
.download-iphone {
  width: 250px;
  height: 48px;
  border-radius: 31px;
  background: #ffffff;
  border: 1px solid #13c6ce;
  margin: 35px auto;
  padding-left: 54px;
  display: flex;
  align-items: center;
}
.download-iphone span {
  font-size: 20px;
  line-height: 48px;
  color: #13c6ce;
  /* font-weight: 600; */
}
.download-iphone img {
  display: inline-block;
  width: 20px;
  height: 22px;
  margin-right: 8px;
}
</style>
